/* Custom script for Walt Disney World brand v2.0.0 */
/* Includes all devices (mobile/desktop) */
/* Author: Lindsay Prahalad/Tom Nelson, October 2015 */

// -------------------------------------------------------------------------------------------------------------------

// Global variables

// Setup brand variable
var brand = 'wdw'

// -------------------------------------------------------------------------------------------------------------------

// Modal dialogs

// Intermission

// Set character/nickname/fact
function intermissionData() {
    // Set random character
    var randomise = Math.floor(Math.random() * 10);
    character = [
        'buzz',
        'woody',
        'rex',
        'alien',
        'sulley',
        'mater',
        'merida',
        'dory',
        'nemo',
        'mr-potato-head'
    ][randomise];
    nickname = literal('wdw.nicknames[' + randomise + ']');

    // Set random fact
    var randomise = Math.floor(Math.random() * 11);
    fact = literal('wdw.facts[' + randomise + ']');
}

// -------------------------------------------

// Prompt user upgrade to Hotel & Ticket package; user can disimss
function upgrade(saving, upsellOverride) {
    // Unbind the existing listener on .cancel
    $('.cancel').unbind();
    // Set up response variable for GA event
    var response;
    // Bind new listener to 'No Thanks' button
    $('#upgrade .cancel').bind('click', function () { response = 'Decline'; });
    // Bind new listener to 'Add Tickets' button
    $('#upgrade .button').bind('click', function () { response = 'Accept'; });
    // Record upgrade selection in hidden field; delay form submission by 0.25 seconds to allow GAQ to fire
    $('#upgrade input').bind('click', function () { var action = $('<input/>', { type: 'hidden', name: 'action', value: response }); action.appendTo($(this)); GA.sendEvent( 'Book', 'Upgrade', response); setTimeout(function () { $('#form').submit(); }, 250); });
    // Override upsell message if passed in (set in data attribute on calling page)
    if (upsellOverride != null) { $('#offer-upsell').text(upsellOverride) }
    // Set Combine & Save value
    if (saving != null) { $('#saving').text(saving); }
    // Dialog is dismissable
    dismissable = true;
    $('#dialog').addClass('dismissable');
    // Add intermission class to dialog
    $('#dialog').addClass('upgrade warning has-buttons');
    // Show dialog
    modal('#upgrade', false);
}

// -------------------------------------------

// Display overlay for tickets that require acknowledgement of missing dine plan option
function acknowledgeDining(selectedTicketIdentifier) {
    // Dialog is dismissable
    $('#acknowledge-dining input').unbind();
    dismissable = true;
    $('#dialog').addClass('dismissable');
    // Add request class to dialog
    $('#dialog').addClass('warning has-buttons');
    //Add selected ticket identifier to hidden form element so it can be captured on postback
    $('#selectedTicketDetail').remove(); $('#acknowledge-dining input').bind('click', function () { var action = $('<input/>', { id: 'selectedTicketDetail', type: 'hidden', name: selectedTicketIdentifier, value: 'Select' }); action.appendTo($(this)); });
    // Show dialog
    modal('#acknowledge-dining', false);
}

// Display overlay for on request rooms that can only be booked via the call centre; user can disimss
function request() {
    // Dialog is dismissable
    dismissable = true;
    $('#dialog').addClass('dismissable');
    // Track in GA
    GA.sendEvent( 'Book', 'Enquiry', 'Request Room');
    // Add request class to dialog
    $('#dialog').addClass('request has-image');
    // Show dialog
    modal('#request', false);
}

// -------------------------------------------

// Display overlay for pre-registration rooms that can only be booked via the call centre; user can disimss
function register() {
    // Dialog is dismissable
    dismissable = true;
    $('#dialog').addClass('dismissable');
    // Track in GA
    GA.sendEvent( 'Book', 'Enquiry', 'Pre-Registration Room');
    // Add register class to dialog
    $('#dialog').addClass('register has-image');
    // Show dialog
    modal('#register', false);
}

// -------------------------------------------

// Display overlay for Cruise and Stay
function cruise() {
    // Dialog is dismissable
    dismissable = true;
    $('#dialog').addClass('dismissable');
    // Track in GA
    GA.sendEvent( 'Book', 'Enquiry', 'Cruise and Stay');
    // Add cruise class to dialog
    $('#dialog').addClass('cruise has-image');
    // Show dialog
    modal('#cruise-stay', false);
}

// -------------------------------------------

// Display verification overlay for ticket opt-out; user can dismiss
function verify() {
    // Dialog is dismissable
    dismissable = true;
    $('#dialog').addClass('dismissable');
    // Set class
    $('#dialog').addClass('warning has-buttons');
    // Track view in GA
    GA.sendEvent( 'Book', 'Enquiry', 'Add Tickets');
    // Show dialog
    modal('#verify', false);
}

// -------------------------------------------

// Display overlay for themed offline deals
function offlineDeal() {
    // Dialog is dismissable
    dismissable = true;
    $('#dialog').addClass('dismissable');
    // Track in GA
    GA.sendEvent( 'Book', 'Enquiry', 'Offline Deal');
    // Add class to dialog
    $('#dialog').addClass('offline-cta has-image');
    // Show dialog
    modal('#offline-cta', false);
}

// -------------------------------------------------------------------------------------------------------------------

// Submit interstitial after image has loaded
function interstitial() {
    // Dialog is not dismissable
    dismissable = false;
    // Set body to position:fixed to force toolbars
    fixBody(true);
    // Get form action
    var url = $('form').attr('action');
    // set timeout to redirect to target page following image load
    setTimeout(function () { window.location.replace(url); }, 3000);

}

// -------------------------------------------------------------------------------------------------------------------

// Geo-location

// Display overlay for geo-location selection
function geo() {
    // Dialog is dismissable
    dismissable = true;
    $('#dialog').addClass('dismissable');
    // Set class
    $('#dialog').addClass('warning');
    // Show dialog
    modal('#geo', true);
}

// Geo-location switching
$('header .sublinks .geo').click(function (event) { geo(); });

// Show modal when geo-location in off-canvas nav is tapped (mobile only)
$('.mobile nav .geo').click(function () {
    snap(); // Snap off-canvas closed
    geo(); // Show popup
});



// -------------------------------------------------------------------------------------------------------------------

// Bookflow

// Google Analytics: track new searches with virtual pageview for funnel reporting
$('#itinerary a, #parameters a').click(function (event) { event.preventDefault(); _gaq.push(['_trackPageview', '/walt-disney-world/book/new-search/']); var href = $(this).attr('href'); setTimeout(function () { window.location = href; }, 250); });

// Manage popup for transfers
function transfers() {
    GA.sendEvent( 'Book', 'Enquiry', 'Disney\'s Magical Express');
    warning(literal('magical-express-title'), literal('magical-express-message'));
};

// Select flights
$('.flight .transfers a').click(function (event) { event.preventDefault(); transfers(); });
$(".flight .information a").click(function (event) { event.preventDefault(); $(this).toggleClass('open'); $(this).closest('hgroup').find('.details .itinerary').slideToggle(); });
$('.filter #direct').change(function (event) { setTimeout(function () { if ($('.filter #direct').is(':checked')) { GA.sendEvent('Book', 'Direct Flights Toggle', 'True'); } else { GA.sendEvent('Book', 'Direct Flights Toggle', 'False');} $('#form').submit(); }, 500); });
if ($('#direct').prop('disabled')) { $('.filter fieldset').click(function () { warning(literal('no-direct-flights-title'), literal('no-direct-flights-message')); GA.sendEvent( 'Book', 'Enquiry', 'No Direct Flights'); }); }

// Track users switching to Hotel + Ticket package when flights are out of range
$('.availability a').click(function (event) { event.preventDefault(); GA.sendEvent( 'Book', 'Switch', 'Hotel + Ticket'); _gaq.push(['_trackPageview', '/walt-disney-world/book/switch/from-flights/to-hotel-ticket/']); var href = $(this).attr('href'); setTimeout(function () { window.location = href; }, 250); });

// Select room
if ($('.room').length) {
    // Check action on room select
    $('#page input[type="submit"], #page input[type="button"]').click(function (event) {
        // Check for  flight provider (optional)
        var supplier; if ($('#flights').length) { supplier = $('#flights').val(); }
        var flights = false; if (supplier == 'GoldMedal' || supplier == 'Dnata') { flights = true; }
        // Store room data in hidden field, where possible
        $('input[type="hidden"][name="room"]').remove(); if (jQuery(this).hasAttr('name')) { var room = $('<input/>', { type: 'hidden', name: 'room', value: $(this).attr('name') }); room.appendTo($(this)); };
        // Check if room is 'on request' and prevent form submission
        if (($(this).attr('data-request') == 'true') || ($('input:radio:checked').attr('data-request') == 'true')) { event.preventDefault(); request(); }
        // Check if room requires pre-registration and prevent form submission
        else if (($(this).attr('data-register') == 'true') || ($('input:radio:checked').attr('data-register') == 'true')) { event.preventDefault(); register(); }
        // Prompt user to add tickets if on Hotel Only path; pass Combine & Save discount if applicable
        else if ($('#upgrade').length) {
            var saving = null;
            var upsellOverride = null;
            if (jQuery(this).hasAttr('data-upsell-override')) { upsellOverride = $(this).attr('data-upsell-override') };
            if (jQuery(this).hasAttr('data-combine')) { saving = $(this).attr('data-combine'); } else if (jQuery('input:radio:checked').hasAttr('data-combine')) { saving = $('input:radio:checked').attr('data-combine'); };
            if (saving != "0") { event.preventDefault(); upgrade(saving, upsellOverride) };
        }
    });
    // Collapse on unload
    $(window).unload(function () { collapse(); }); $(window).bind('pagehide', function () { collapse(); });
};

// Select Tickets
if ($('#ticket').length) {
    $('#page input[type="submit"], #page input[type="button"]').click(function (event) {
        var acknowledge = jQuery(this).attr('data-acknowledge');
        if (acknowledge != null) { event.preventDefault(); acknowledgeDining(acknowledge); };
    });
    $('.no-tickets a').click(function (event) { event.preventDefault(); verify(); });
};

// Select Dining
$('.no-dining a').click(function (event) { event.preventDefault(); $('#form').submit(); })
$('a.dining-plan').click(function () { GA.sendEvent( 'Download', 'Dining', $(this).attr('data-plan')); })

// Itinerary
$('.itinerary .transfers a').click(function (event) { event.preventDefault(); transfers(); });

// -------------------------------------------------------------------------------------------------------------------

// Static pages (e.g. non-bookflow)

// Cruise & Stay
$('#cruise .button, #cruise .launch').click(function (event) { event.preventDefault(); cruise(); });

// Themed modal
$('#themed-modal .launch').click(function (event) { event.preventDefault(); offlineDeal(); });

// -------------------------------------------------------------------------------------------------------------------

// Planning tools

// Track online brochure and online LBBOM views
if($("#body.free-planning").length) {
	
	$(".brochure .online").click(function() {
		GA.sendEvent( 'Planning Tools', 'WDTC Brochure', 'View Online');
	});

	$(".lbbom .online").click(function() {
		GA.sendEvent( 'Planning Tools', 'The Little Big Book of Magic', 'View Online');
	});

    // Check if this is the confirmation screen and fire GA event
	if ($("#body.free-planning").attr('data-step') == 'confirmation') {
	    GA.sendEvent( 'Planning Tools', 'Order', 'Confirmation (1-Click)');
	}
}

// Check valid DoB
$('#body.free-planning #day, #body.free-planning #month, #body.free-planning #year').change(function () {
    var ageError = [literal('over-18-title'), literal('over-18-tools')];
    validDoB('#body.free-planning #day', '#body.free-planning #month', '#body.free-planning #year', ageError);
});

//Room name change info modal on mmb
$('#room-name-change-info a').click(function (event) { event.preventDefault(); warning(literal('room-name-change-title'), literal('room-name-change-message')); });

// Check valid country
$('#body.planning #country').change(function () {
    // Non UK or Ireland?
    if ($(this).val() == 'Rest of Europe') { warning(literal('overseas-tools'), literal('overseas-tools-message')); }
})

$('#country').change(AppendEUAdvisoryText);

function AppendEUAdvisoryText() {
    var euAdvisory = $('#eu-advisory');
    var locale = $('#country').attr('data-locale');


    if (euAdvisory) {
        euAdvisory.remove();
    }
    
    var contactDetailsPayment = $('.contact.details.payment');
    var euAdvisoryText = GetEUAdvisoryText($('#country :selected').val());

    if (euAdvisoryText && !['esp','deu'].includes(locale)) {
        contactDetailsPayment.after("<div id='eu-advisory' class='contact details'><p class='icon info multiline'>" + euAdvisoryText + "</p><hr></div>");
    }
}

function GetEUAdvisoryText(country) {
    var texts = {
        'Austria': 'Die Dienstleistungen auf dieser Webseite werden nicht f&uuml;r &Ouml;sterreich angeboten. Bitte wenden Sie sich an einen lokalen Reiseanbieter, sofern Sie eine Buchung f&uuml;r &Ouml;sterreich vornehmen m&ouml;chten.',
        'Belgium': 'Alle verstrekte informatie en gemaakte boekingen (inclusief juridische voorwaarden) op deze website zijn alleen in het Engels en vallen onder de Engelse wet en jurisdictie. U erkent dat alle boekingen die u op deze website maakt, vrijwillig zijn gemaakt en dat u alle inhoud voldoende hebt begrepen. Als u niet akkoord gaat met juridische voorwaarden in het Engels, neem dan contact op met uw lokale reisaanbieder voor informatie en boekingen in uw lokale tal.<br><br>Toutes  les  informations  fournies  et  les  r&eacute;servations  effectu&eacute;es  sur  ce  site  internet,  y  compris  les dispositions l&eacute;gales y aff&eacute;rentes, sont en anglais uniquement et sont soumises au droit anglais et &agrave; la comp&eacute;tence  des  tribunaux  anglais.  Vous  reconnaissez  que  toute  r&eacute;servation  sur  ce  site  internet  de votre part est effectu&eacute;e en connaissance de cause et que vous vous estimez suffisamment inform&eacute;(e) pour  ce  faire.  Si  vous  refusez  de  vous  engager  en  anglais,  vous  pouvez  contacter  votre  agence  de voyage locale pour obtenir des informations et effectuer des r&eacute;servations dans votre langue.',
        'France': 'Toutes  les  informations  fournies  et  les  r&eacute;servations  effectu&eacute;es  sur  ce  site  internet,  y  compris  les dispositions l&eacute;gales y aff&eacute;rentes, sont en anglais uniquement et sont soumises au droit anglais et &agrave; la comp&eacute;tence  des  tribunaux  anglais.  Vous  reconnaissez  que  toute  r&eacute;servation  sur  ce  site  internet  de votre part est effectu&eacute;e en connaissance de cause et que vous vous estimez suffisamment inform&eacute;(e) pour  ce  faire.  Si  vous  refusez  de  vous  engager  en  anglais,  vous  pouvez  contacter  votre  agence  de voyage locale pour obtenir des informations et effectuer des r&eacute;servations dans votre langue.',
        'Germany': 'Alle Informationen auf dieser Webseite und die Buchungen (einschlie&szlig;lich der Nutzungsbedingungen) werden  ausschlie&szlig;lich  in  englischer  Sprache  zur  Verf&uuml;gung  gestellt  und  es  gilt  englisches  Recht  und Gerichtsbarkeit.  Sie  erkennen  an,  dass  alle  Buchungen,  die  Sie  auf  dieser  Website  vornehmen,  aus freien St&uuml;cken erfolgen und dass Sie alle Inhalte ausreichend verstanden haben. Wenn Sie nicht damit einverstanden sind, an englisches Recht in englischer Sprache gebunden zu sein, wenden Sie sich bitte stattdessen an Ihren lokalen Reiseanbieter, um Informationen und Buchungen in Ihrer Landessprache zu erhalten.',
        'Italy': 'Tutte le informazioni fornite e le prenotazioni effettuate sul presente sito web (inclusi i relativi termini e condizioni legali) sono esclusivamente in lingua inglese, e sono soggette alla legge inglese nonch&eacute; alla  giurisdizione  delle autorit&agrave;  giudiziarie  inglesi.  Voi  prendete  atto  che  tutte  le  prenotazioni  da  voi effettuate  sul  presente  sito  web  avvengono  su  base  volontaria  e  dichiarate  di  aver  sufficientemente compreso  tutti  i  contenuti  disponibili  sul  presente  sito  web.  Qualora  non  intendiate  effettuare  una prenotazione  in  lingua  inglese,  e  soggetta  a  termini  e  condizioni  legali  redatti  in  lingua  inglese,  vi preghiamo di contattare il vostro fornitore di viaggi locale per informazioni e prenotazioni nella vostra lingua locale.',
        'Spain': 'Toda la informaci&oacute;n proporcionada (incluidas las condiciones legales) y las reservas realizadas en este sitio web est&aacute;n s&oacute;lo en ingl&eacute;s as&iacute; como sujetas a la legislaci&oacute;n y jurisdicci&oacute;n inglesa. Usted reconoce que  cualquier  reserva  que  realice  en  este  sitio  web  lo  hace  de  manera  voluntaria  y  que  comprende suficientemente todo su contenido. Si no est&aacute; de acuerdo con los t&eacute;rminos legales en ingl&eacute;s, por favor, p&oacute;ngase en contacto con su proveedor de viajes local para obtener informaci&oacute;n y reservas en su idioma local.',
    };

    if (!texts[country] && country !== 'United Kingdom') {
        return 'All bookings (including legal terms) made on this website are subject to English law and jurisdiction. If you do not agree to be bound by English terms please instead contact your local Travel Provider.'
    }

    return texts[country];
}

// Track marcomms opt-in in Google Analytics
$('#body.planning #newsletter').change(function () {
    if ($(this).prop('checked') == true) { GA.sendEvent( 'Marketing', 'Planning', 'Opt-In'); } // Opt-in
});

//Disable the book button on passenger details and contact details pages to prevent agents creating multiple bookings and guests creating multiple options by spamming the respective buttons
$('#bookbutton').click(function () {
    if ($(this).hasClass("disabled")) return false;
    $(this).addClass("disabled");
    return true;
});

// -------------------------------------------------------------------------------------------------------------------

// On ready
$(document).ready(function () {

    $('body').keydown(function() {
        if ($('#bookbutton').hasClass("disabled")) {
            $('#bookbutton').removeClass("disabled");
        }
    });
    // Interstitial
    if ($('body').hasClass('interstitial')) { interstitial(); };

    AppendEUAdvisoryText();

    // Google Analytics: track geo-location switching
    $('#geo a.geo, footer a.geo').click(function (event) {
        // Prevent default
        event.preventDefault();
        // Track switch in GA
        GA.sendEvent( 'Geolocation', 'Switch', $(this).attr('data-geo'));
        // Get target URL
        var href = $(this).attr('href');
        // Redirect to target URL after .25 second delay
        setTimeout(function () { window.location = href; }, 250);
    });
});
